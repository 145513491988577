import React from "react";
import styles from "./aboutme.module.css";
import { MdArrowOutward } from "react-icons/md";
import me from "../assets/mudshot.png";

const AboutMe = () => {
  return (
    <article id="aboutme" className={`${styles.aboutContainer} odd-page`}>
      <main>
        <section className={styles.textContainer}>
          <section
            className={styles.positionContainer}
            onClick={() => window.open("https://www.annafreud.org/")}
            role="button"
            tabIndex="0"
            aria-label="Open anna freud website"
            onKeyDown={(e) => {
              if (e.key) {
                window.open("https://www.annafreud.org/");
              }
            }}
          >
            <h3 className={styles.textHeading}>
              Web Developer &#183; Anna Freud (London)
              <span className={styles.arrowContainer}>
                <MdArrowOutward size={20} />
              </span>
            </h3>
            <div>
              <em>2024 - Present</em>
            </div>
            <p style={{ lineHeight: "1.2" }}>
              Developed POD for mental health providers (e.g., NHS Trusts).
              Built TypeScript UI components from Figma, added Highcharts for
              data visualization, and refined MVP features with feedback.
              Collaborate with backend developers on APIs & tickets, ensuring
              cohesive development & advocating for best practices
            </p>
            <p className={styles.techList}>
              <span className={styles.techEl}>NextJS</span>
              <span className={styles.techEl}>TypeScript</span>
              <span className={styles.techEl}>Tamagui</span>
              <span className={styles.techEl}>HighCharts</span>
              <span className={styles.techEl}>Prisma</span>
              <span className={styles.techEl}>MySQL</span>
              <span className={styles.techEl}>PHP</span>
            </p>
          </section>

          <section
            className={styles.positionContainer}
            onClick={() => window.open("https://5app.com/")}
            role="button"
            tabIndex="0"
            aria-label="Open 5app website"
            onKeyDown={(e) => {
              if (e.key) {
                window.open("https://5app.com/");
              }
            }}
          >
            <h3 className={styles.textHeading}>
              Frontend Engineer &#183; 5app (London)
              <span className={styles.arrowContainer}>
                <MdArrowOutward size={20} />
              </span>
            </h3>
            <div>
              <em>2022 - 2024</em>
            </div>
            <p style={{ lineHeight: "1.2" }}>
              Refactored JavaScript to TypeScript, improving maintainability.
              Fixed UI/UX bugs, enhancing satisfaction. Turned designs into
              templates, documented with Storybook. Added Cypress tests for
              reliability. Helped seniors implement accessibility standards
            </p>
            <p className={styles.techList}>
              <span className={styles.techEl}>ReactJS</span>
              <span className={styles.techEl}>React-Query</span>
              <span className={styles.techEl}>Express</span>
              <span className={styles.techEl}>Docker</span>
              <span className={styles.techEl}>Storybook</span>
              <span className={styles.techEl}>Styled Components</span>
              <span className={styles.techEl}>Cypress</span>
            </p>
          </section>
        </section>

        <section className={styles.imgContainer}>
          <img src={me} alt="Photograph of me" />
        </section>
      </main>
    </article>
  );
};

export default AboutMe;

/* 
          <p>
            I'm a front-end developer based in London, UK. Originally from the
            bustling city of Hong Kong, I moved to California to earn my degree
            in Psychology at Berkeley.
          </p>

          <p>
            My love affair with programming began unexpectedly during my time as
            a researcher at university. Tasked with transcribing and extracting
            data from the internet, I spent countless weeks (maybe even months)
            manually crunching numbers. That is, until I discovered that a few
            lines of code could do it all in seconds... &#128529;
          </p>

          <p>
            Before I developed a "psychological bond" with coding (some might
            call it Stockholm Syndrome), I worked with individuals with
            emotional & learning difficulties. So, yes, I went from managing
            meltdowns to debugging code—turns out, both require a lot of
            patience!
          </p>

          <p>
            Interests: Reading detective stories, cheering for AC Milan, and
            occasionally pretending to be Sherlock Holmes of the coding world.
          </p> */
