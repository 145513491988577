import React from "react";
import styles from "./techstack.module.css";

const frontendStack = [
  "React",
  "Next.js",
  "TypeScript",
  "Redux",
  "Jest & Cypress",
  "jQuery",
  "Tailwind",
];
const backendStack = [
  "Node.js",
  "Express",
  "MySQL",
  "Prisma",
  "MongoDB",
  "Mongoose",
];
const generalStack = [
  "Version Control",
  "Automated Testing",
  "Responsive Design",
  "Storybook",
  "WCAG",
  "REST",
];

function Wrapper({ title, children }) {
  return (
    <section>
      <h3>{title}</h3>
      <main className={styles.details}>{children}</main>
    </section>
  );
}

const TechStack = () => {
  return (
    <section id="techstack" className={`${styles.skillContainer} even-page`}>
      <header>
        <h1>Skills</h1>
        <div className={styles.headingUnderline}></div>
      </header>
      <div className={styles.text}>
        <Wrapper title="Frontend">
          {frontendStack.map((skill, index) => (
            <p key={index} role="listitem">
              {skill}
            </p>
          ))}
        </Wrapper>
        <Wrapper title="Backend">
          {backendStack.map((skill, index) => (
            <p key={index} role="listitem">
              {skill}
            </p>
          ))}
        </Wrapper>
        <Wrapper title="General">
          {generalStack.map((skill, index) => (
            <p key={index} role="listitem">
              {skill}
            </p>
          ))}
        </Wrapper>
      </div>
    </section>
  );
};

export default TechStack;
